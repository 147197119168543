import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2e59b11e = () => interopDefault(import('../pages/config.ts' /* webpackChunkName: "pages/config" */))
const _2c055ba8 = () => interopDefault(import('../pages/daily_report.vue' /* webpackChunkName: "pages/daily_report" */))
const _489503ac = () => interopDefault(import('../pages/driver_schedule.vue' /* webpackChunkName: "pages/driver_schedule" */))
const _5637efea = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _9b88d772 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _de8886c6 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/config/",
    component: _2e59b11e,
    pathToRegexpOptions: {"strict":true},
    name: "config"
  }, {
    path: "/daily_report/",
    component: _2c055ba8,
    pathToRegexpOptions: {"strict":true},
    name: "daily_report"
  }, {
    path: "/driver_schedule/",
    component: _489503ac,
    pathToRegexpOptions: {"strict":true},
    name: "driver_schedule"
  }, {
    path: "/forget_password/",
    component: _5637efea,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/login/",
    component: _9b88d772,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reset_password/",
    component: _de8886c6,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/",
    redirect: "/daily_report/",
    name: "index"
  }, {
    path: "*",
    redirect: "/daily_report/",
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
